// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// dev Url
export const environment = {
  production: false,
  env: 'normal',
  apiUrlAdmin:"https://dev-api.pirospace.com/space-module/",
  apiUrlDemo:"https://dev-api.pirospace.com/user-module/",
  apiUrlVenue:"https://dev-api.pirospace.com/space-module/",
  apiUrlAssets:"https://dev-api.pirospace.com/user-module/",
  apiUrlUser:"https://dev-api.pirospace.com/user-module/",
};

// staging Url

// export const environment = {
//   production: false,
//   env: 'normal',
//   apiUrlAdmin:"https://stage-v1-api.pirospace.com/space-module/",
//   apiUrlDemo:"https://stage-v1-api.pirospace.com/user-module/",
//   apiUrlVenue:"https://stage-v1-api.pirospace.com/space-module/",
//   apiUrlAssets:"https://stage-v1-api.pirospace.com/user-module/",
//   apiUrlUser:"https://stage-v1-api.pirospace.com/user-module/",
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
